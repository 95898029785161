import React, { useState, useEffect } from 'react';
import './TypingText.css';

const TypingText = () => {
    const fullText = "Human or Not";
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        if (currentIndex < fullText.length && currentIndex !== -1) {
            const delay = (currentIndex === fullText.length - 1) ? 500 : 20;
            const timer = setTimeout(() => {
                setDisplayedText((prevText) => prevText + fullText[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [currentIndex]);

    useEffect(() => {
        const starterTimer = setTimeout(() => {
            setCurrentIndex(0);
        }, 600);
        return () => clearTimeout(starterTimer);
    }, []);

    return (
        <div className="text-container">
            <span>{displayedText}</span>
            <span className="caret">|</span>
        </div>
    );
}

export default TypingText;
