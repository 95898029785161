import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import von react-helmet-async
import './App.css';

// Deine Komponenten für die verschiedenen Seiten
import Faq from './Faq';
import Privacy from './Privacy';
import TypingText from './TypingText';
import Home from './Home';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Helmet>
            <title>Human or Not - Bot Chat Game</title>
            <meta name="description" content="Human or Not is an innovative bot chat game that blurs the lines between human and machine." />
            <meta name="keywords" content="Human or Not, Human or AI, Human or Bot, AI Chat Game, Human vs machine" />
          </Helmet>
          <header className="App-header">
            <h1><TypingText /></h1>
          </header>

          {/* Hier wird der Hauptinhalt der Anwendung gerendert */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>

          {/* Navigationsleiste am unteren Rand der Seite */}
          <footer className="App-footer">
            <Link to="/faq">FAQ</Link>
            <Link to="/privacy">Privacy Policy</Link>
          </footer>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
