import React from 'react';
import './Privacy.css'; // Stelle sicher, dass du die entsprechende CSS-Datei hast

function Privacy() {


var policy = `Privacy Policy

1. Introduction

Welcome to "Human or Bot". This website is owned and operated by Stefan Tissen. We respect your privacy and are committed to protecting your personal data. This Privacy Policy will inform you as to how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.

2. Personal Data We Collect

We collect the text of the chat conversations that occur on our website. Our server functionalities are powered by Firebase, which may collect additional information. Please refer to Firebase’s Privacy Policy for more information on what they collect.

3. Use of Your Personal Data

We use the collected data to improve the game and prevent misuse of the game.

4. Sharing of Your Personal Data

We share the collected data with AdMob, a third-party advertising company, to serve ads when you visit our website. Please refer to AdMob’s Privacy Policy for more information on how they use your data. In addition to AdMob, we may also share your data with other advertisers or third parties who place advertisements on our website.

5. Data Security

We use the data security provided by Firebase to ensure the security of the collected data.

6. Data Retention

We will retain the collected data as long as the website is used.

7. Your Rights

You have the right to access, correct, or delete your personal data. Please contact us at humanorbot.team@gmail.com if you wish to exercise any of these rights.

8. Cookies

We use cookies and similar technologies to show ads on our website. These cookies may also be used by other advertisers or third parties to provide targeted advertising on our website.

9. Changes to This Privacy Policy

We may change this Privacy Policy at any time without notice. It is your responsibility to check for updates to this Privacy Policy.

10. Limitation of Liability

We are not liable for any damages arising from the use of our website or the advertisements displayed on it. The use of our website is at your own risk. We are not responsible for the content provided by third-party advertisers or service providers.

11. Applicability to Other Advertisers

This Privacy Policy is applicable not only to AdMob but also to other advertisers or third parties who place advertisements on our website. We may share your data with these parties to enable personalized advertising. Please refer to the privacy policies of these third parties to understand how they handle your data.

12. Contact Us

If you have any questions about this Privacy Policy, please contact us at humanorbot.team@gmail.com.`


  return (
    <div className="privacy-container">
      <h1 className="privacy-heading">Privacy Policy</h1>
      <p className="privacy-text" style={{ whiteSpace: 'pre-line' }}>
        {policy}
      </p>
      {/* Füge zusätzliche Absätze hier ein */}
    </div>
  );
}

export default Privacy;
