import React, { useState } from 'react';
import './Faq.css'; // Stelle sicher, dass du das entsprechende Styling anwendest

const faqs = [
  {
    question: "Is the AI revolution upon us?",
    answer: "While AI is advancing rapidly, our 'Human or Not' game offers a playful perspective on the AI-human dynamic, exploring the evolving relationship between humans and machines."
  },
  {
    question: "What’s the purpose behind this game?",
    answer: "'Human or Not' is a unique AI Chat Game that tests your ability to distinguish between human and AI, sharpening your wits in the age of advanced machine intelligence."
  },
  {
    question: "Will this game also be discontinued?",
    answer: "No, this iteration of 'Human or Not' is here to stay. We're dedicated to providing a continuous platform for users to engage with the Human vs Machine challenge in a fun and educational way."
  },
  {
    question: "Who represents humanity in this game?",
    answer: "Players like you are the champions of humanity, using your intuition and reasoning to navigate through conversations with AI, in a quest to determine 'Human or Bot'."
  },
  {
    question: "What about the AI participants?",
    answer: "The AIs in 'Human or Not' are designed to challenge and entertain, representing the 'Machine' side of 'Human vs Machine'. They're a mix of different models, crafted to provide a realistic and engaging experience."
  },
  {
    question: "Is there a cost to join the adventure?",
    answer: "Nope, 'Human or Not' is free to play! We believe everyone should have the chance to engage with AI through this game, though we do offer premium features for enthusiasts."
  },
  {
    question: "Can you explain the Turing Test?",
    answer: "The Turing Test is a classic measure of a machine's ability to exhibit human-like intelligence, inspiring many aspects of our 'Human or AI' game."
  },
  {
    question: "How do I excel in chat?",
    answer: "To excel in 'Human or Not', be curious and creative in your conversations, whether you're chatting with a human or AI. The best players are those who think outside the box."
  },
  {
    question: "What if I identify an AI in disguise?",
    answer: "Congratulations! It means you've got a keen sense for nuances in conversation that distinguish 'Human or AI' in our game."
  },
  {
    question: "Could there be hidden secrets in the game?",
    answer: "Absolutely. We've sprinkled a few surprises throughout 'Human or Not' for the most observant players to discover."
  },
  {
    question: "If I beat an AI in chat, do I get a cookie?",
    answer: "Consider it a virtual cookie, a token of your savvy in distinguishing 'Human or Bot'. Plus, it's calorie-free!"
  },
  {
    question: "Do the AI bots dream of electric sheep?",
    answer: "If they could dream, our AI bots would dream of complex algorithms and, perhaps, sheep made of code. It's a playful nod to the classic sci-fi question that blurs the line between 'Human or AI'."
  },
  {
    question: "How do I know if I'm not the AI?",
    answer: "The very act of questioning your own nature is a deeply human trait. AIs don't question; they compute. This introspection is what sets the 'Human or Not' challenge apart."
  },
  {
    question: "How can I contact Human or Not?",
    answer: "Reach out to us via email for any inquiries or feedback about the 'Human or AI' game. We're here to ensure your experience is top-notch!"
  },
];



function FaqItem({ faq }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <div className="faq-question">
        {faq.question}
        <span className="faq-toggle">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="faq-answer">{faq.answer}</div>}
    </div>
  );
}

function Faq() {
  return (
    <div className="faq-container">
      <h2>Any Questions?</h2>
      {faqs.map((faq, index) => (
        <FaqItem key={index} faq={faq} />
      ))}
    </div>
  );
}

export default Faq;
