import React from 'react';
import './Home.css'; // Stelle sicher, dass du die entsprechende CSS-Datei hast

function Home() {
  return (
    <div className="Home">
      <p className="tagline">A Social Turing Game.</p>
      <button onClick={() => window.location.href='https://humanorbotgame.com/'} className="play-button">Play Now</button>
      <p className="description">
        Engage in a two-minute conversation and attempt to discern whether your counterpart is human or a bot.<br /><br />
        Do you believe you can spot the difference?
      </p>
    </div>
  );
}

export default Home;
